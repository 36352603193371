const firebaseConfig = {
    apiKey: "<YOUR-API-KEY>",
    authDomain: "<YOUR-PROJECT-ID>.firebaseapp.com",
    projectId: "<YOUR-PROJECT-ID>",
    storageBucket: "<YOUR-PROJECT-ID>.appspot.com",
    messagingSenderId: "<YOUR-MESSANGING-SENDER-ID>",
    appId: "<YOUR-APP-ID>",
    measurementId: "<YOUR-MEASUREMENT-ID>",
    vapidKey:"<YOUR-VAPIDKEY>"
};

export default firebaseConfig;

